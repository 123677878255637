
/* Icon Classes */
.neurelisIcons {
	.icon {
		
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 40px;
		height: 40px;
	}

	.phone {
		background-image: url(../images/icons/phone.svg);
	}

	.cme {
		background-image: url(../images/icons/cme.svg);
	}

	.cme-white {
		background-image: url(../images/icons/cme-white.svg);
	}

	.cme-white-color {
		background-image: url(../images/icons/cme-white-color.svg);
	}

	.infographics {
		background-image: url(../images/icons/infographics.svg);
	}

	.infographics-white {
		background-image: url(../images/icons/infographics-white.svg);
	}

	.infographics-white-color {
		background-image: url(../images/icons/infographics-white-color.svg);
	}

	.manuscripts {
		background-image: url(../images/icons/manuscripts.svg);
	}

	.manuscripts-white {
		background-image: url(../images/icons/manuscripts-white.svg);
	}

	.manuscripts-white-color {
		background-image: url(../images/icons/manuscripts-white-color.svg);
	}

	.microsite {
		background-image: url(../images/icons/microsite.svg);
	}

	.microsite-white {
		background-image: url(../images/icons/microsite-white.svg);
	}

	.microsite-white-color {
		background-image: url(../images/icons/microsite-white-color.svg);
	}

	.msl {
		background-image: url(../images/icons/msl.svg);
	}

	.msl-white {
		background-image: url(../images/icons/msl-white.svg);
	}

	.msl-white-color {
		background-image: url(../images/icons/msl-white-color.svg);
	}

	.podcasts {
		background-image: url(../images/icons/podcasts.svg);
	}

	.podcasts-white {
		background-image: url(../images/icons/podcasts-white.svg);
	}

	.podcasts-white-color {
		background-image: url(../images/icons/podcasts-white-color.svg);
	}

	.posters {
		background-image: url(../images/icons/posters.svg);
	}

	.posters-white {
		background-image: url(../images/icons/posters-white.svg);
	}

	.posters-white-color {
		background-image: url(../images/icons/posters-white-color.svg);
	}

	.safety {
		background-image: url(../images/icons/safety.svg);
	}

	.safety-white {
		background-image: url(../images/icons/safety-white.svg);
	}

	.safety-white-color {
		background-image: url(../images/icons/safety-white-color.svg);
	}

	.slide {
		background-image: url(../images/icons/slide.svg);
	}

	.slide-white {
		background-image: url(../images/icons/slide-white.svg);
	}

	.slide-white-color {
		background-image: url(../images/icons/slide-white-color.svg);
	}

	.toolbox {
		background-image: url(../images/icons/toolbox.svg);
	}

	.toolbox-white {
		background-image: url(../images/icons/toolbox-white.svg);
	}

	.toolbox-white-color {
		background-image: url(../images/icons/toolbox-white-color.svg);
	}
	.download{
		background-image: url(../images/icons/download-icon.svg);
	}
	.actionplan{
		background-image: url(../images/icons/action-icon.svg);
	}
	.manuscript{
		background-image: url(../images/icons/manuscript-icon.svg);
	}
	.transcript{
		background-image: url(../images/icons/transript-icon.svg);
	}
	
}