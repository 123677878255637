
/* Common Classes */

.links {
	cursor: pointer;
}

.boldText {
	font-weight: 700;
}

.bottomBorder {
	border-bottom:1px solid black;
}

.programBottomSpacing {
	margin-bottom: 30px;
}

.preWrap {
	white-space: pre-wrap;
}

.contactUsModal {
	width: 600px;
}

@media screen and (max-width: 576px) {

	.p-dialog-header {
		padding-top: 0px !important;
	}

	.redirectModal {

		.title {
		
			margin-bottom: .5rem !important;
			padding-bottom: .5rem !important;
			font-size: 24px !important;
			line-height: 26px !important;
	
		}

		.body {
			padding: 15px 15px !important;
			font-size: 16px !important;
		}
	}
}


.redirectModal {
	width: 800px;
	height: 350px;
	border: 3px solid #00BCB4;

	.title {
		border-bottom: 1px solid #00BCB4;
    	margin-bottom: 1rem;
    	padding-bottom: 1rem;
    	font-weight: normal;
    	font-size: 28px;
    	line-height: 48px;
    	margin-top: 0px;
    	color: #00BCB4;
	}

	.body {
		padding: 20px 20px;
		font-size: 18px;
	}

	.buttons {
		.noButton:hover {
			background: #00BCB4 !important;
    		color: white !important;
    		border: 0 none !important;
		}
		
		.yesButton {
			background: #00BCB4;
    		color: white;
    		border: 0 none;
		}

		.yesButton:hover {
			background: white;
    		color: #00bcb4;
    		border: 0 none;
		}
	}
}

.link {
	cursor: pointer;
}

.link:hover {
	color: #199bfc;
}

.programListing {
	margin:0 120px
}

.stickyHeader {
	position:fixed;
    top:0px;
	width: 100%;
	z-index: 1000;
	background: white;
	min-height: 5rem;
}

.loginPanel {
	border-left: 3px solid $turquoise;
}

.flipIcon {
	transform: rotate(180deg);
}

.header {
	height: 85px;
	
	.headerButtons {
		padding-top: 25px;
		padding-right: 25px;
	}

	.logo {

		padding-left: 48px;
		padding-top: 12px;
	
		img {
			width: auto;
			height: 36px;
			display: block;
		}
		span {
			display: block;
			font-weight: 600;
			color: #0E1D61;
		}
		@media screen and (max-width:960px) {
			padding-left: 12px;
			padding-top: 12px;
			padding-bottom: 12px;
		}
	}

	.showMenu {
		opacity: 1;
		transition: .3s all ease;
		position: relative;
		z-index: 1;
	}

	.hideMenu {
		opacity: 0;
		transition: .3s all ease;
		position: relative;
		z-index: 0;
		
	}

	.showSearch {
		opacity: 1;
		transition: .3s all ease;
		position: relative;
		top: -65px;
		z-index: 1;
	}

	.hideSearch {
		opacity: 0;
		transition: .3s all ease;
		position: relative;
		top: -65px;
		z-index: 0;
	}

	.searchBar {
		.p-autocomplete {
			width: 100%;

			input {
				width: 100%;
				padding-right: 40px;
			}
		}

		.p-autocomplete-loader {
			display: none;
		}
	}

	

	.menuBar {
		.p-menubar {
			border: none;
			.p-menuitem-text {
				font-weight: 700;
			}

			.searchIcon {
				cursor: pointer;
			}
		}
	}
}

.autoCompletePanel {
	max-width: 300px;

	.p-autocomplete-item {
		white-space: normal;
	}
}

.page {
	padding-left: $pageLeftPadding;
	padding-right: 24px;

	h1 {
		color: $blue;
	}
}

.fullField {
	width: 100%;
}

.halfField {
	width: 48%;
}

.oneThirdField {
	width: 32%;
}

.twoThirdField {
	width: 64%;
}

.inputForm {
	.inputFormFields {
		>div {
			border-bottom-width: 1px;
			border-bottom-style: solid; 
			border-bottom-color:rgba(14,29, 97, 0.15);
		}

		>div:nth-child(odd) {
			background-color:rgba(14,29, 97, 0.05);
		}
	}
	
	
	h2 {
		color: $blue;
	}
	
	.field {
		padding-left: 10px;
		padding-right: 10px;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	input, .p-dropdown, .p-inputnumber {
		width: 100%;
	}
	
	label {
		font-weight: 700;
	}

	.oddRow, .evenRow  {
		border-bottom-width: 1px;
		border-bottom-style: solid; 
		border-bottom-color:rgba(14,29, 97, 0.15);
	}

	.oddRow {
		background-color:rgba(14,29, 97, 0.05);
	}

	.submitButton {
	
		
		height: 35px;
		width: 50%;
		padding: 3px 12px; 
		margin-top: 1rem;
		margin-bottom: 1rem;
		color: white;
		border-radius: 3px;
		cursor: pointer;
		text-align: center;
		background-color: $turquoise;
	}
}

.footer{
	background-color: #f3f2ff;
	font-family: 'Rajdhani';
	padding-left:48px;
	overflow: hidden;
	margin-top: 20px;

.footersocial-container {
	display: flex;
	align-items: left;
	margin-right: 0px;
  }
  
  .footersocial p {
	display: inline; 
	margin-right: 0px;
	font-size: 1rem;
	list-style-type: none;
	color: $blue;
	font-weight: 600;
	line-height: 175%;
	padding-left: 10px; 
  }
  
  .footersocial a {
	margin-left: 8px; /* Adjust the margin-left value to control the spacing between the icons */
  }
  
	ul {
		padding:0px;
		padding-left:0px;
		padding-bottom:10px;
		margin:0px;
	}
	.ftlink{
			cursor: pointer;	
			margin:0px;
			padding-bottom: 30px;
		li{
			font-size: 1rem;
			list-style-type: none;
			color: $blue;
			font-weight: 600;
			line-height: 175%;
			padding-left: 20px;
		}
		a{
			color: $blue !important;
		};
		ul{
			padding-top: 5px;
			border-left: solid 1px #CCCCCC;
			min-height: 90px;
			max-height: 90px;
		}
	}
	.fttxt{
		padding-top: 20px;
		padding-bottom: 20px;
			p{
				line-height: 50%;
				font-weight: 600;
				color: #1b1c1b;
			}
	}
	.ftlogo{
		padding-top: 20px;
	}

	.logo {

		padding-left: 5px;
		padding-top: 25px;
	
		img {
			height: 36px;
		}
	}

}

.subheader {
	margin-bottom: 30px;
	.blue {
		background: $blue;
		background-image: url(../images/resources-banner.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 24px;
		padding-bottom: 24px;
		color: white;
		padding-left: 128px;
		padding-right: 128px;

		h1 {
			text-transform: uppercase;
			font-family: 'Rajdhani';
			font-style: normal;
			font-weight: 700;
			font-size: 3rem;
		}

		h2 {
			text-transform: uppercase;
			font-family: 'Rajdhani';
			font-style: normal;
			font-weight: 600;
			font-size: 1.5rem;
		}

		.description {
			font-size: 1rem;
		}
	}
	.breadcrumbs {
		height: 32px;
		padding-left: $pageLeftPadding;
		background: $grayBackground;
	}
}

.subMenu {
	width: 256px;
	margin-right: 96px;
	border: 1px solid $turquoise;
	color: $blue;
	font-weight: 700;

	.subMenuRow {
		height: 56px;
		padding: 5px;
		border-bottom: 1px solid $turquoise;
		cursor: pointer;
	}

	.subMenuRow:last-child {
		border-bottom: 0;
	}

	.subMenuRow.selected {
		background: $blue;
		color: white;
		cursor: auto;
	}

	.name {
		padding-left: 10px;
		
	}

	.openIcon {
		padding-right: 5px;
	}
	&.contactmenu {
	    border: 2px solid #00BCB4;
	    border-radius: 4px;
	    .subMenuRow {
		    height: auto;
		    .info-group {
			    padding-left: 11px;
			    .name {
				    font-size: 1rem;
				    line-height: 160%;
				    color: #0E1D61;
				    padding: 0;
				}
				.description {
				    font-weight: 300;
				    font-size: .8rem;
				    line-height: 140%;
				    color: #0E1D61;
				}
			}
			.openIcon{
				color: #0E1D61;
			}
		}
	}
}

.submission{
		 display: grid;	 
}

.page {
	&.contactpage {
	   	padding-left: 128px;
	    padding-right: 128px;

		.confirmation {
			font-size: 1.5rem;
			font-weight: 700;
			text-align: center;
			padding-left: 40px;
		}
	}
	&.events-page {
	    padding-right: 128px;
	}

}
.submit-question-form{
	p.note {
	    font-weight: 300;
	    font-size: .8rem;
	    line-height: 140%;
	    color: #0E1D61;
	    a{
	    	color: #0E1D61;
	    }
	}
	label {
	    font-weight: 500;
	    font-size: 1rem;
	    color: #0E1D61;
	    &.radio-label {
		    font-size: .8rem;
		    line-height: 140%;
		    color: #1D1D1B;
		    font-weight: 400;
		}
	}
	input.p-inputtext, .p-dropdown, textarea.p-inputtextarea {
	    border: 1px solid #D7E6F0;
	    border-radius: 6px;
	}
	button.p-button.p-component {
	    background: #00BCB4;
	    border-radius: 0;
	    font-weight: 700;
	    font-size: .7rem;
	    line-height: 14px;
	    letter-spacing: 0.08em;
	    text-transform: uppercase;
	    padding: 12px 18px 12px 18px;
	    width: 256px;
	    height: 48px;
	}
	.p-radiobutton{
		.p-radiobutton-box{
			.p-radiobutton-icon {
			    background-color: #00A399;
			}
		}
	}
	.p-component {
	    width: 100%;
	}
	&.follow-up-form{
		.p-component.p-checkbox {
		    width: auto;
		}
	}
}

.grayText {
	color: $grayText;
}

.backLink {
	color: $turquoise;
	font-weight: 700;
}

.resource {
	min-height: 40px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-right: 80px;
	border-bottom: 1px solid #D7E6F0;

	.shortTitle {
		color: $blue;
		font-weight: 700;
		font-size: 1rem;
	}
}

.yearTitleBar {
	background: #FCF6E0;
	margin-right: 80px;
	color: $turquoise;
	font-weight: 800;
	font-size: 1.3rem;
	margin-top: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.oddRow {
	background: #F5F5FD;
}

.red {
	color: red;
}

.resourceNotice {
	padding-top: 20px;
	font-size: 1rem;
}

.subcatMenuButton {
	> div {
		border: 1px solid $turquoise;
		border-radius: 5px;
		padding: 5px;
		color: $blue;
		cursor: pointer;
	}

	.selected {
		background-color: $turquoise;
		color: white;
	}
}

.mslCard {
	border-bottom: 1px solid $turquoise;
	margin-bottom: 25px;
	margin-left: 20px;
	img {
		margin-left: 50px;
		margin-bottom: 25px;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		overflow: hidden;
		border: 3px solid $turquoise;
		padding: 3px;

	}

	.title {
		font-weight: 700;
	}
}

.mp3Icon {
	
	width: 35px;
	height: 35px;
	padding: 3px;
	padding-left: 6px;
	color: white;
	border-radius: 3px;
	cursor: pointer;
	text-align: center;
	
	i {
		font-size: 20px;
	}
}

.playIcon {
	background-color: $turquoise;
}

.pauseIcon {
	background-color: $blue;
	padding-left: 3px;
}

.mp3Button {
	height: 25px;
	padding: 3px;
	padding-left: 5px;
	padding-right: 8px;
	color: $blue;
	border-radius: 3px;
	border: 1px solid $grayText;
	cursor: pointer;
	text-align: center;
	background: white;
	margin-right: 0px;
	font-weight: 700;
	i {
		color: $turquoise;
	}
	a{
		font-size: .7rem;
	    letter-spacing: 0.08em;
	    text-transform: uppercase;
	    color: #0E1D61;
	}
	.neurelisIcons{
		.icon {
			height: 19px;
		    width: 20px;
		    margin-right: 5px;
		}
	}
}

.eventCard {
	img {
		margin-top: 20px;
		width: 300px;
		overflow: hidden;
	}

	.title {
		font-weight: 700;
	    color: #0E1D61;
	    font-size: 1rem;
	    line-height: 140%;
	}
	.event {
	    border-color: #D7E6F0;
	}

	.eventDates {
		background: #0E1D61;
		position: absolute;
		top: 25px;
		left: 25px;
		height: 30px;
		color: white;
		padding: 7px;
		padding-left: 15px;
		padding-right: 15px;
		border-radius: 5px;
	}
}

.mt-30{
	margin-top: 30px;
}

.ct{
	margin-left:65px;
	font-weight: 700 !important;
	font-size: 1rem !important;;
}

.grants-main{
	.description {
	    text-align: center;
	}
	.subMenu {
	    border-radius: 4px;
	    border: 2px solid #00A399;
	    background: #00A399;
	}
	.external-menu {
	    background: #F5F5FD;
	    padding: 24px 12px 10px 12px;
	    .external-tooltip {
		    background: #00A399;
		    border-radius: 4px;
		    padding: 12px;
		    font-weight: 700;
		    font-size: .8rem;
		    line-height: 140%;
		    color: #FFFFFF;
		    position: relative;
		    margin-bottom: 8px;
		    &:after {
			    content: "";
			    border-bottom: 16px solid #00A399;
			    border-left: 16px solid transparent;
			    position: absolute;
			    bottom: -7px;
			    transform: rotate(45deg);
			    left: 18px;
			}
		}
		.subMenuRow {
		    padding: 0;
		    .name {
			    padding: 0;
			}
		}
	}
	.page{
		padding-right: 128px;
		h1 {
		    font-weight: 600;
		    font-size: 2rem;
		    line-height: 100%;
		    text-transform: uppercase;
		    color: #0E1D61;
		}
	}
.grants-body-content2{
	margin: 20px;
	h2{
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 100%;
		text-transform: uppercase;
		color: #0E1D61;

		.m-top-10{
			margin-top: 10px;
		}
	}
	p {
		font-weight: 300;
		font-size: 1rem;
		line-height: 160%;
		color: #1D1D1B;
		-webkit-font-smoothing: auto;
		a {
			color: #00A399;
		}
	}
}
	.grants-body-content{
		margin-bottom: 35px;
		h2{
			font-weight: 600;
		    font-size: 1.5rem;
		    line-height: 100%;
		    text-transform: uppercase;
		    color: #0E1D61;

			.m-top-10{
				margin-top: 10px;
			}
		}
		p {
		    font-weight: 300;
		    font-size: 1rem;
		    line-height: 160%;
		    color: #1D1D1B;
		    -webkit-font-smoothing: auto;
		    a {
			    color: #00A399;
			}
		}
		&>ul {
		    padding: 0;
		    margin: 0;
		    list-style: none;
		    &>li {
			    position: relative;
			    padding-left: 24px;
			    font-style: normal;
			    font-weight: 300;
			    font-size: .8rem;
			    line-height: 140%;
			    color: #1D1D1B;
			    margin-bottom: 10px;
			    -webkit-font-smoothing: auto;
			    &:before {
				    content: "";
				    position: absolute;
				    left: 0;
				    background-image: url(../images/icons/plus-icon.svg);
				    background-repeat: no-repeat;
				    background-position: center;
				    width: 15px;
				    height: 15px;
				}
				> ul {
				    list-style: disc;
				    padding: 0 0 0 18px;
				    margin: 9px 0 0 0;
				    li {
					    font-style: normal;
					    font-weight: 300;
					    font-size: .8rem;
					    line-height: 140%;
					    color: #1D1D1B;
					    margin-bottom: 4px;
					    -webkit-font-smoothing: auto;
					}
				}
			}
		}
		.button-link{
			a {
			    background: #00BCB4;
			    font-weight: 700;
			    font-size: .8rem;
			    line-height: 15px;
			    letter-spacing: 0.08em;
			    text-transform: uppercase;
			    color: #FFFFFF;
			    padding: 17px 21px;
			    display: inline-block;
			    -webkit-font-smoothing: auto;
			    margin-top: 20px;
			}
		}
	}
	.grants-notice {
	    padding: 0 30px;
	     p {
		    font-weight: 300;
		    font-size: 1rem;
		    line-height: 160%;
		    color: #1D1D1B;
		    a {
			    color: #00A399;
			}
		}
	}
}
a.p-menuitem-link:empty {
    display: none;
}

.text-black{
	color: black;
}

.category-checkbox {
    padding: 18px 15px;
    border-bottom: 1px solid #00BCB4;
    .p-checkbox.p-component {
	    order: 2;
	    margin-left: auto;
	}
	label {
	    font-weight: 600;
	    font-size: 14px;
	    line-height: 140%;
	    color: #0E1D61;
	}
}

.clear-filter-btn {
    font-weight: 500;
    font-size: 14px;
    color: #0E1D61;
    cursor: pointer;
    padding: 20px 10px;
}

@media screen and (min-width: 961px) {
	.mobileOnly {
		display: none !important;
	}
}

@media screen and (max-width: 960px) {
	.desktopOnly {
		display: none !important;
	}

	/* If input size is less than 16px, it does an auto zoom on mobile and throws off design */
	.p-inputtext {
		font-size: 1rem;
	}

	.mobileSearch, .mobileExpanders {
		padding-top: 30px;
		i {
			font-size: 1.5rem;
			cursor: pointer;
			color: $blue;
		}
	}

	.header {
		height: auto;
	}

	.mobileMenuRow {
		margin-left: 15px;
		margin-right: 15px;
		height: 60px;
		border-bottom: 1px solid $blue;
	}
	

	.mobileExpanders {
		padding-right: 10px;
		padding-top: 17px;
	}

	.reportMessage {
		color: $blue;
		padding-top: 30px;
		padding-right: 20px;
		padding-left: 20px;
		line-height: 1.5;
		a{
			color: $blue;
		}
	}

	.searchBar {
		width: 100%;
		padding: 7px;

		input {
			border: 2px solid $blue;
		}
		
	}

	.autoCompletePanel {
		max-width: 100%;
		margin-left: 7px;
		margin-left: 7px;
	}

	.subheader {
		margin-bottom: 15px;
		.blue {
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 90px;
			background-size: 300%;
			background-position: top;
			h1{
				font-size: 2rem;
			}
		}
		.breadcrumbs {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.page {
		padding-left: 10px;
		padding-right: 10px;
		&.contactpage{
			padding-left: 10px;
    		padding-right: 10px;
		}
		&.events-page {
		    padding-right: 10px;
		}
	}

	.grants-main{
		.page{
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	

	.subMenu {
		width: 100%;
		margin-right:0;
		border-radius: 5px;
		position: relative;
		top: -90px;
		z-index: 1000;
		background: white;
		&.contactmenu {
		    top: 0;
		    margin-bottom: 24px;
		    margin-top: 10px;
		}
		&.pipeline{
			top: 0;
		    margin-bottom: 24px;
		    margin-top: 10px;
		}
	}

	.submit-form-btn{
		text-align: center !important;
	}

	.resource {
		margin-right: 5px;
	}
}

.homeEvents {
	.title {
		font-size: 1.5rem;
		font-weight: 700
	}
}

.searchPage {
	.searchItem {
		border-bottom: 1px solid #CCCCCC;

		.title {
			font-size: 1.1rem;
			font-weight: 700;
			color: $blue;
		}
	}

	.loginBox {

		.loginMessage {
			font-size: 1rem;
			font-weight: 700;
			padding-bottom: 10px;
		}
		height: 150px;
		background: #FCF6E0;
	}
}

.resource{
	.rhap_main {
	    margin-bottom: 8px;
	    &.rhap_horizontal .rhap_controls-section {
		    top: 6px;
		    position: relative;
		}
	}
	.rhap_container {
	    background: transparent !important;
	    border: none;
	    box-shadow: 0 0;
	    padding: 0;
	    .rhap_additional-controls, .rhap_main-controls {
		    display: none;
		}
		.rhap_progress-section {
			flex-wrap: wrap;
		    flex: auto;
		    width: 100%;
			.rhap_progress-container {
			    margin: 0;
			    order: 3;
			    width: 100%;
			}
			.rhap_time{
				font-weight: bold;
			    font-size: 10px;
			    line-height: 11px;
			    letter-spacing: 0.08em;
			    text-transform: uppercase;
			    color: #0E1D61;
			    &.rhap_total-time:before {
				    content: "/";
				    font-size: 11px;
				}
			}
			.rhap_progress-indicator {
			    display: none;
			}
			.rhap_progress-bar, .rhap_progress-bar-show-download, .rhap_download-progress {
			    background: #D7E6F0;
			}
			.rhap_progress-filled{
				background: #00BCB4;
			}
		}
		.rhap_volume-controls{
			button.rhap_volume-button {
			    color: #0E1D61;
			}
			.rhap_volume-bar-area{
				display: none;
			}
		}
	}
}


@media screen and (max-width: 960px) {

	.footer  {
	 
		.ftlink{
				
			border-left: solid 0px #CCCCCC;
		}
	}

  }

@media screen and (max-width: 480px) {

	.footer  {
	 


	  ul {

		padding-left:48px;
		padding:0px;
		margin:0px;
	  }

	}
}

.bg-secondary {
    background: #00A399;
}

.border-secondary{
	border-color: #00A399;
}

/****** Home Slider ******/

.p-carousel .p-carousel-content{
	position: relative;
	.p-carousel-container {
	    position: relative;
	    button.p-link {
		    position: absolute;
		    top: 50%;
		    transform: translate(0,-50%);
		    left: 50px;
		    margin: 0 !important;
		    z-index: 9;
		    background: #00BCB4;
		    border-radius: 0;
		    width: 48px;
		    height: 48px;
		    color: #fff;
		    @media screen and (max-width: $tabletBreakpoint) {
		    	display: none;
		    }
		    &.p-carousel-next {
			    right: 50px;
	    		left: auto;
			}
		}
	}
	ul.p-carousel-indicators {
	    @media screen and (max-width: $tabletBreakpoint) {
	    	position: relative;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    background: #fff;
		    padding: 20px 0 15px 0;
	    }
	    .p-carousel-indicator{
	    	@media screen and (max-width: $tabletBreakpoint) {
	    		margin: 0 20px;
	    	}
	    	button.p-link {
			    width: 50px;
			    border: 1px solid #00BCB4;
			    background: #00BCB4;
			    height: 6px;
			}
			&.p-highlight button.p-link {
			    background: #fff;
			}
	    }
	}
}

.slider-outer {
    position: relative;
    .bg-image {
	    position: absolute;
	    top: 0;
	    height: 100%;
	    width: 70%;
	    z-index: -9;
	    @media screen and (max-width: 767px) {
	    	width: 50%;
	    }
	    img{
	    	width: 100%;
	    	height: 100%;
	    	object-fit: cover;
	    }
	}
	.right-content {
	    background: #0E1D61;
	    padding: 10rem 0;
	    @media screen and (max-width: $tabletBreakpoint) {
	    	padding: 6rem 0;
	    }
	    @media screen and (max-width: $phoneBreakpoint) {
	    	padding: 25px 0;
	    }
	    h3 {
		    font-family: DINNextLTPro-MediumCond;
		    font-style: normal;
		    font-weight: 500;
		    font-size: 3rem;
		    line-height: 57px;
		    letter-spacing: -0.04em;
		    text-transform: uppercase;
		    margin: 0;
		    color: #ffffff;
		    position: relative;
			left: -5px;
			@media screen and (max-width: $tabletBreakpoint) {
				font-size: 35px;
			    line-height: 22px;
			    margin-bottom: 17px !important;
			    left: -3px;
			}
			@media screen and (max-width: $phoneBreakpoint) {
				font-size: 19.1111px;
			    line-height: 23px;
			    margin: 0 !important;
			}
		}
		h2.large-heading1 {
		    font-family: DINNextLTPro-MediumCond;
		    font-style: normal;
		    font-weight: 500;
		    font-size: 155.764px;
		    line-height: 76%;
		    letter-spacing: -0.04em;
		    text-transform: uppercase;
		    left: -12px;
		    position: relative;
		    color: #ffffff;
		    @media screen and (max-width: $tabletBreakpoint) {
		    	font-size: 65px;
			    line-height: 32px;
			    margin-top: 30px !important;
			    left: -5px;
		    }
		    @media screen and (max-width: $phoneBreakpoint) {
		    	font-size: 46.5127px;
			    line-height: 76%;
			    margin: 6px 0 0 0 !important;
			    left: -2px;
		    }
		}
		h2.large-heading2 {
		    font-family: DINNextLTPro-MediumCond;
		    font-style: normal;
		    font-weight: 500;
		    font-size: 132px;
		    line-height: 76%;
		    letter-spacing: -0.04em;
		    text-transform: uppercase;
		    left: -7px;
		    position: relative;
		    color: #ffffff;
		    @media screen and (max-width: $tabletBreakpoint) {
		    	font-size: 70px;
    			margin: 20px 0 !important;
		    }
		    @media screen and (max-width: $phoneBreakpoint) {
		    	font-size: 39.4167px;
			    line-height: 76%;
			    margin: 7px 0 0 0 !important;
			    left: -3px;
		    }
		}
	}
}

.complaint-bar {
    background: #00BCB4;
    padding: 17px 15px;
    display: inline-block;
    width: 100%;
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 160%;
    text-align: center;
    color: #ffffff;
    a{
    	color: #ffffff;
    }
}

.search-resources {
    padding: 100px 20px;
    background: #F5F5FD;
    position: relative;
    overflow: hidden;
    z-index: 9;
    @media screen and (max-width: 767px) {
    	padding: 48px 20px;
    }
    &:before {
	    content: "";
	    background-image: url(../images/search-icon-left.svg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    width: 370px;
	    height: 380px;
	    position: absolute;
	    left: -90px;
	    bottom: -80px;
	    z-index: -9;
	    @media screen and (max-width: 767px) {
	    	display:none;
	    }
	}
	&:after {
	    content: "";
	    background-image: url(../images/search-icon-right.svg);
	    background-repeat: no-repeat;
	    background-position: center center;
	    width: 420px;
	    height: 380px;
	    position: absolute;
	    right: -90px;
	    top: -80px;
	    z-index: -9;
	    @media screen and (max-width: 767px) {
	    	display:none;
	    }
	}
    h2 {
	    font-family: 'Rajdhani';
	    font-weight: bold;
	    font-size: 3rem;
	    line-height: 100%;
	    text-align: center;
	    text-transform: uppercase;
	    color: #0E1D61;
	    margin-bottom: 30px;
	    @media screen and (max-width: 767px) {
	    	font-size: 2rem;
	    }
	}
	p {
	    font-family: 'Ubuntu';
	    font-style: normal;
	    font-weight: 300;
	    font-size: 1rem;
	    line-height: 160%;
	    text-align: center;
	    color: #1D1D1B;
	    margin-bottom: 24px;
	}
	.seach-section {
	    max-width: 736px;
	    width: 100%;
	    margin: 0 auto;
	    @media screen and (max-width: $tabletBreakpoint) {
	    	max-width: 530px;
	    }
	    .p-inputwrapper {
		    width: 100%;
		    input[type="text"] {
			    width: 100%;
			    background: #FFFFFF;
			    border: 1px solid #D7E6F0;
			    border-radius: 6px;
			    color: #000000;
			    font-family: 'Ubuntu';
			    font-weight: 400;
			    font-size: 16px;
			    line-height: 140%;
			}
		}
	}
}


.latest-podcasts-section {
    padding-top: 140px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    z-index: 9;
    @media screen and (max-width: 767px) {
    	padding-top: 48px;
    	padding-bottom: 48px;
    }
    &:after {
	    content: "";
	    background-image: url(../images/podcast-polygon-icon-right.svg);
	    background-repeat: no-repeat;
	    width: 130px;
	    height: 230px;
	    position: absolute;
	    right: 0;
	    top: 100px;
	    z-index: -9;
	    @media screen and (max-width: 767px) {
	    	display:none;
	    }
	}
    .container {
	    max-width: 1170px;
	    margin: 0 auto;
	}
	.podcast-image {
	    position: relative;
	    background: #0E1D61;
	    z-index: 9;
	    &:before {
		    content: "";
		    background-image: url(../images/podcast-polygon-icon1.svg);
		    background-repeat: no-repeat;
		    width: 190px;
		    height: 168px;
		    position: absolute;
		    bottom: -40px;
		    left: -90px;
		    z-index: -9;
		    @media screen and (max-width: 767px) {
		    	display:none;
		    }
		}
	    .icon {
		    position: absolute;
		    right: 30px;
		    top: -17px;
		    @media screen and (max-width: 767px) {
		    	left: 0;
		    	right:0;
		    	margin: 0 auto;
		    	width: 90px;
		    }
		}
		img {
		    width: 100%;
		    display: block;
		}
		.info {
		    padding: 25px 25px;
		    font-family: 'Ubuntu';
		    font-style: normal;
		    font-weight: 300;
		    font-size: 1rem;
		    line-height: 160%;
		    color: #FFFFFF;
		    background: #0E1D61;
		    @media screen and (max-width: 767px) {
		    	text-align: center;
		    }
		}
	}
	.content {
	    padding-left: 30px;
	    position: relative;
	    height: 100%;
	    z-index: 9;
	    @media screen and (max-width: 767px) {
	    	padding: 0;
	    	text-align: center;
	    }
	    &:after {
		    content: "";
		    background-image: url(../images/podast-multiple-ply-icon.svg);
		    background-repeat: no-repeat;
		    width: 156px;
		    height: 107px;
		    position: absolute;
		    bottom: 0px;
		    right: 60px;
		    margin: 0 auto;
		    z-index: -9;
		    @media screen and (max-width: 767px) {
		    	display:none;
		    }
		}
	    h2 {
		    font-family: 'Rajdhani';
		    font-weight: bold;
		    font-size: 3rem;
		    line-height: 100%;
		    text-transform: uppercase;
		    color: #0E1D61;
		    margin-bottom: 25px;
		    @media screen and (max-width: 767px) {
		    	font-size: 2rem;
		    }
		}
		p {
		    font-family: 'Ubuntu';
		    font-weight: 300;
		    font-size: 1rem;
		    line-height: 160%;
		    color: #1D1D1B;
		}
	}
	a.all-btn-link {
	    display: inline-block;
	    background: #00BCB4;
	    border-radius: 0;
	    padding: 17px 18px;
	    font-family: 'Ubuntu';
	    font-style: normal;
	    font-weight: 700;
	    font-size: .8rem;
	    line-height: 14px;
	    letter-spacing: 0.08em;
	    text-transform: uppercase;
	    color: #FFFFFF;
	    margin-top: 22px;
	    position: relative;
    	z-index: 9;
    	@media screen and (max-width: 767px) {
    		text-align: center;
    		width: 100%;
    	}
	}
}


.latest-events-section {
    padding: 30px 20px 140px 20px;
    @media screen and (max-width: 767px) {
    	padding-bottom: 290px;
    }
    .container {
	    max-width: 735px;
	    margin: 0 auto;
	}
	h2 {
	    font-family: 'Rajdhani';
	    font-weight: 700;
	    font-size: 3rem;
	    line-height: 100%;
	    text-transform: uppercase;
	    color: #0E1D61;
	    margin-bottom: 30px;
	    @media screen and (max-width: 767px) {
	    	font-size: 2rem;
	    }
	}
	.event-info {
	    margin-bottom: 32px;
	    p {
		    font-family: 'Ubuntu';
		    font-style: normal;
		    font-weight: 300;
		    font-size: 1rem;
		    line-height: 160%;
		    text-align: center;
		    color: #1D1D1B;
		}
	}
	.events-main-feature {
	    max-width: 740px;
	    width: 100%;
	    margin: 0 auto 32px;
	    position: relative;
	    &:before {
			background: #e9e9f4;
			content: "";
			height: calc(100% - 46px);
			left: -28px;
			position: absolute;
			top: 23px;
			width: calc(100% + 54px);
			z-index: 1;
		    @media screen and (max-width: $tabletBreakpoint) {
		    	display: none;
		    }
		}
		img {
			display: block;
			position: relative;
			width: 100%;
			z-index: 2;
		}
		&:after {
			background: #f5f5fd;
			content: "";
			display: block;
			height: calc(100% - 84px);
			left: -50px;
			position: absolute;
			top: 43px;
			width: calc(100% + 98px);
			z-index: 0;
		    @media screen and (max-width: $tabletBreakpoint) {
		    	display: none;
		    }
		}
	}
	a.all-btn-link {
	    background: #00BCB4;
	    border-radius: 0;
	    padding: 17px 18px;
	    font-family: "Ubuntu";
	    font-style: normal;
	    font-weight: 700;
	    font-size: .8rem;
	    line-height: 14px;
	    letter-spacing: 0.08em;
	    text-transform: uppercase;
	    color: #FFFFFF;
	    display: block;
	    width: 161px;
	    margin: 0 auto;
	    @media screen and (max-width: 767px) {
    		text-align: center;
    		width: 100%;
    	}
	}
}

.grants-section {
    background: #00BCB4;
    position: relative;
    padding: 80px 20px;
    border-bottom: 100px solid #f3f2ff;
    @media screen and (max-width: 767px) {
    	border: 0px;
    	padding-top: 0px;
    	padding-bottom: 48px;
    }
    .icon{
    	&1 {
		    position: absolute;
		    left: -30px;
		    bottom: 80px;
		    @media screen and (max-width: 767px) {
		    	display: none;
		    }
		}
		&2 {
		    position: absolute;
		    left: 70px;
		    top: 154px;
		    width: 56px;
		    transform: rotate(29deg);
		    @media screen and (max-width: 767px) {
		    	display: none;
		    }
		    img {
			    width: 100%;
			}
		}
		&3 {
		    position: absolute;
		    left: 0;
		    top: -10px;
		    right: 0;
		    text-align: center;
		    width: 45px;
		    margin: 0 auto;
		    transform: rotate(51deg);
		    @media screen and (max-width: 767px) {
		    	display: none;
		    }
		    img {
			    width: 100%;
			}
		}
		&4 {
		    position: absolute;
		    left: 20%;
		    bottom: -60px;
		    @media screen and (max-width: 767px) {
		    	display: none;
		    }
		}
		&5 {
		    position: absolute;
		    right: 12%;
		    top: 0px;
		    @media screen and (max-width: 767px) {
		    	display: none;
		    }
		}
    }
    .container {
	    max-width: 1170px;
	    margin: 0 auto;
	}
	.grid {
	    align-items: center;
	}
	.medical-form-section {
	    background-color: #fff;
	    background-image: url(../images/medical-education-bg.png);
	   	background-repeat: no-repeat;
	    background-position: left 42px;
	    background-size: cover;
	    border: 8px solid #F5F5FD;
	    text-align: center;
	    padding: 30px 30px;
	    position: absolute;
	    top: -50px;
	    height: calc(100% + 100px);
	    width: 387px;
	    @media screen and (max-width: 991px) {
	    	width: 297px;
	    }
	    @media screen and (max-width: 767px) {
	    	position: relative;
		    margin: 0 auto -200px;
		    top: -240px;
	    }
	    @media screen and (max-width: $phoneBreakpoint) {
	    	width: 250px;
		    padding: 20px 20px 30px 20px;
		    border: 3.81818px solid #F5F5FD;
	    }
	    h3 {
		    font-family: 'Rajdhani';
		    font-weight: bold;
		    font-size: 28.8px;
		    line-height: 100%;
		    text-align: center;
		    text-transform: uppercase;
		    color: #0E1D61;
		    opacity: 0.48;
		    margin: 10px 0 30px 0;
		    @media screen and (max-width: $phoneBreakpoint) {
		    	font-size: 17px;
		    }
		}
		h5.form-title {
		    font-family: 'Rajdhani';
		    font-weight: bold;
		    font-size: 14.2964px;
		    line-height: 100%;
		    text-transform: uppercase;
		    color: #0E1D61;
		    opacity: 0.48;
		}
	}
	form.medical-grant-form {
	    max-width: 250px;
	    margin: 20px auto 0;
	    .input-row {
		    padding: 0;
		    display: flex;
		    align-items: flex-end;
		    justify-content: center;
		    margin-bottom: 16px;
		    flex-wrap: wrap;
		    label {
			    font-family: 'Rajdhani';
			    font-weight: 700;
			    font-size: 14.2964px;
			    line-height: 100%;
			    text-transform: uppercase;
			    color: #0E1D61;
			    opacity: 0.48;
			    width: 80px;
			    text-align: left;
			    @media screen and (max-width: $phoneBreakpoint) {
			    	width: 74px;
			    }
			}
			input.p-inputtext {
			    background: transparent;
			    border: none;
			    border-bottom: 2px solid #0E1D61;
			    border-radius: 0;
			    padding: 0;
			    width: 170px !important;
			    box-shadow: 0 0;
			    @media screen and (max-width: 991px) {
			    	width: 130px !important;
			    }
			    @media screen and (max-width: $phoneBreakpoint) {
			    	width: 100px !important;
			    }
			}
			span.error {
			    display: block;
			    width: 100%;
			}
		}
		.submit-form-btn {
		    display: block;
		    margin-top: 62px;
		    button.p-button {
			    display: block;
			    background-color: transparent;
			    background-image: url(../images/submit-vector-img.svg);
			    background-repeat: no-repeat;
			    background-position: left center;
			    border-bottom: 2px solid #0E1D61;
			    border-radius: 0;
			    width: 127px;
			}
		}
	}
	.content {
	    padding-left: 80px;
	    @media screen and (max-width: 767px) {
	    	padding: 0;
	    	text-align: center;
	    }
	    h2 {
		    font-family: 'Rajdhani';
		    font-weight: bold;
		    font-size: 3rem;
		    line-height: 100%;
		    text-transform: uppercase;
		    color: #FFFFFF;
		    margin-bottom: 30px;
		    @media screen and (max-width: 767px) {
		    	font-size: 2rem;
		    }
		}
		p {
		    font-family: 'Ubuntu';
		    font-style: normal;
		    font-weight: 500;
		    font-size: 1rem;
		    line-height: 160%;
		    color: #FFFFFF;
		}
		a.all-btn-link {
		    display: inline-block;
		    background: #ffffff;
		    border-radius: 0;
		    padding: 17px 18px;
		    font-family: "Ubuntu";
		    font-style: normal;
		    font-weight: 700;
		    font-size: .8rem;
		    line-height: 14px;
		    letter-spacing: 0.08em;
		    text-transform: uppercase;
		    color: #00BCB4;
		    margin-top: 22px;
		    @media screen and (max-width: 767px) {
	    		text-align: center;
	    		width: 100%;
	    	}
		}
	}
}

.mslCard a {
    color: #00A399;
}
.my-account{
	.inputForm{
		max-width: 980px;
	    margin: 50px auto;
	    width: 100%;
	    @media screen and (max-width: 767px) {
	    	margin: 30px auto;
	    }
		.field {
		    margin: 0;
		}
		.submitButton {
		    width: 180px;
		    font-weight: bold;
		    margin: 0;
		}
	}
}

.resetPassword {

	margin: auto;
	
	.inputForm {
		width: 480px;
	    margin: 50px auto;
		
		.submitButton {
		    width: 280px;
		    font-weight: bold;
		}
	}

	@media screen and (max-width: 767px) {
    	.inputForm {
			width: 90vw;
		}
    }
}

.infographics-model.p-dialog{
	.p-dialog-header {
	    background: transparent;
	    position: absolute;
	    top: 0;
	    left: 0;
	    padding: 0;
	    width: 100%;
	    button.p-dialog-header-icon {
		    width: 60px;
		    padding-top: 15px;
		    background: transparent !important;
		    span.p-dialog-header-close-icon {
			    background: #fff;
			    border-radius: 40px;
			    width: 20px;
			    height: 20px;
			    text-align: center;
			    padding: 3.9px 0 0 0;
			}
		}
	}
	.p-dialog-content{
		padding: 0;
    	overflow: hidden;
    	border-radius: 4px;
		iframe {
		    width: 100%;
		    height: 100%;
		    border: 0px;
		}
	}
}

.pi-arrow-up-right:before {
    background-image: url(../images/icons/external-link.svg);
   	background-repeat: no-repeat;
   	background-position: center;
   	width: 10px;
   	height: 10px;
   	display: block;
   	font-size: 0px;
}

.registration-required-header{
	button.p-button {
	    background: #00bcb4 !important;
	    color: #fff !important;
	    text-align: center;
	    display: block;
	    font-weight: bold;
	}
}
form.submit-question-form {
    position: relative;
}
form.locked {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    .overlay {
	    z-index: 20;
	    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
	    position: absolute;
	    top: 0px;
	    left: 0px;
	    height: 100%;
	    width: 100%;
	}
}

.about-main{
	.subtitle {
	    font-weight: 600;
	    color: #00BCB4;
	}
	a.all-btn-link {
	    display: inline-block;
	    background: #00BCB4;
	    border-radius: 0;
	    padding: 17px 18px;
	    font-family: "Ubuntu";
	    font-style: normal;
	    font-weight: 700;
	    font-size: 0.8rem;
	    line-height: 14px;
	    letter-spacing: 0.08em;
	    text-transform: uppercase;
	    color: #FFFFFF;
	    margin-top: 22px;
	    position: relative;
	    z-index: 9;
	}
	.larger-img {
		img {
			width: 100%;
		    max-width: 100%;
		}
	}
}

.about-card{
	.about-card-img{
		img {
		    display: block;
		    width: 100%;
		}
	}
	.about-content {
	    box-shadow: 0px 13px 23px #00000026;
	    margin-left: -100px;
	    @media screen and (max-width: 767px) {
	    	margin-left: 0px;
	    }
	    h2{
	    	font-weight: 600;
	    	color: #00BCB4;
	    }
	}
	&:nth-child(2n + 2) {
		flex-direction: row-reverse !important;
		@media screen and (max-width: 767px) {
			flex-direction: column !important;
		}
		.about-content {
			margin-left: 0;
			margin-right: -100px;
			z-index: 2;
			@media screen and (max-width: 767px) {
		    	margin-right: 0px;
		    }
		}
	}
	
}


@media screen and (max-width: 767px) {
	.mob-hidden{
		display: none;
	}
	.autoCompletePanel{
		margin-left: 0px;
	}
	.p-autocomplete-panel{
		.p-autocomplete-items{
			.p-autocomplete-item {
			    padding: 6px 16px;
			}
		}
	}
	.subcatMenuButton > div{
		width: 100%;
		margin-right: 0px !important;
	}
}

@media screen and (max-width: 991px) {
	.about-main{
		.subMenu {
		    top: 0;
		    margin-bottom: 20px;
		    margin-top: 5px;
		}
		.subheader{
			.blue {
			    padding-bottom: 24px;
			}
		}
	}
}

.blueBG {
	background-color: $blue;
}

.complaint-callout
{
	background: #FCF6E0;
    padding: 17px 15px;
    display: inline-block;
    width: 100%;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 160%;
    text-align: center;
    color: #1D1D1B;
}

