/* Add your customizations of the layout styles here */

.topbar-left .logo-image {
	width: 180px;
	height: 23px;
	vertical-align: middle;
	margin-right: 0.5rem;
  }

.login-body {
    background: $bodyBg;
    color: $textColor;

	display: flex;
	justify-content: center;

	.card {
		width: 425px;
		height: 425px;
		position: absolute;
		top: 50%;
		padding: 40px;
		margin-top: -300px;

		.logos {
			height: 48px;
			padding-bottom: 100px;
			text-align: left;

			img {
				width: 250px;
			}
			
		}

		.login-form {
			text-align: center;
			padding-left: 70px;
			padding-right: 70px;

			label {
				text-align: left;
			}

			.p-button {
				background-color: $buttonColor;
				width: 100%;
			}

			.p-inputtext, .p-password-input {
				width: 100%;
			}

			.rememberMe {
				text-align: left;
			}
			
		}
	}
}

.layout-topbar {
	.logo {
		img {
			width: 120px;
		}
	}
}

.tableHeader {
	font-size: 18px;
	font-weight: 700;
}

.p-breadcrumb {
	background: #f8f9fa !important;
	border: 0 !important;
}




