.layout-search {
    @include flex-align-center();
    @include flex-justify-center();
    @include flex();
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    pointer-events: none;

    .search-container {
        background: $searchBg;
        @include flex();
        @include flex-align-center();
        font-size: 1.5rem;
        padding: 2rem;
        width: 50vw;
        border-radius: .625rem;
        position: relative;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border: $searchBorder;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        pointer-events: all;

        input {
            appearance: none;
            font-size: 1.5rem;
            text-indent: 2.5rem;
            padding: .5rem;
            width: 100%;
        }

        i {
            color: $textSecondaryColor;
            width: 2rem;
            font-size: 1.5rem;
            position: absolute;
            top: 50%;
            margin-top: -.75rem;
            margin-left: 1rem;
        }
    }

    .search-container-enter {
        opacity: 0;
        transform: translateY(20px);
    }

    .search-container-enter-active {
        opacity: 1;
        transform: none;
        transition: opacity $animationDuration $animationTimingFunction,
            transform $animationDuration $animationTimingFunction;
    }

    .search-container-exit {
        opacity: 1;
        transform: none;
    }

    .search-container-exit-active {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity $animationDuration $animationTimingFunction,
            transform $animationDuration $animationTimingFunction;
    }
}
