.exception-body {
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    @include flex();
    @include flex-justify-center();
    @include flex-align-center();
    min-height: 100vh;

    .logo {
        width: 50px;
        position: absolute;
        left: 50px;
        top: 50px;
    }

    .exception-content {
        .exception-title {
            font-size: 112px;
            font-weight: 700;
            color: #ffffff;
        }

        .exception-detail {
            font-weight: 700;
            color: #ffffff;
            margin: 10px 0 0 0;
        }

        > a {
            margin-top: 40px;
            width: 250px;
            display: inline-block;
            text-align: center;
            padding: .5rem 1rem;
            color: #ffffff;
            transition: background-color .2s;
            background-color: rgba(255,255,255,0.1);
            border-radius: $borderRadius;

            &:hover {
                background-color: rgba(255,255,255,0.2);
            }
        }
    }

    &.access {
        background-image: url("../../images/pages/bg-access.jpg");
    }

    &.notfound {
        background-image: url("../../images/pages/bg-404.jpg");
    }

    &.error {
        background-image: url("../../images/pages/bg-error.jpg");
    }
}

@media(max-width:640px) {
    .exception-body {
        .exception-content {
            .exception-title {
                font-size: 72px;
            }
        }
    }
}
