@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: local('Nunito Regular'), local('Nunito-Regular'),
    url("../fonts/nunito-v12-latin-ext_latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/nunito-v12-latin-ext_latin-regular.woff") format('woff'); /* Modern Browsers */
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url("../fonts/nunito-v12-latin-ext_latin-600.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/nunito-v12-latin-ext_latin-600.woff") format('woff'); /* Modern Browsers */
}

@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: local('Nunito Bold'), local('Nunito-Bold'),
    url("../fonts/nunito-v12-latin-ext_latin-700.woff2") format('woff2'), /* Super Modern Browsers */
    url("../fonts/nunito-v12-latin-ext_latin-700.woff") format('woff'); /* Modern Browsers */
}

@font-face {
    font-family: 'DIN Next LT Pro Medium';
    src: local('DIN Next LT Pro Medium'), local('DINNextLTPro-Medium'),
        url('../fonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro-Medium.woff2') format('woff2'),
        url('../fonts/DINNextLTPro-Medium.woff') format('woff'),
        url('../fonts/DINNextLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DINNextLTPro-MediumCond';
    src: url('../fonts/DINNextLTPro-MediumCond.eot');
    src: local('DIN Next LT Pro Medium Condensed'), local('DINNextLTPro-MediumCond'),
        url('../fonts/DINNextLTPro-MediumCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINNextLTPro-MediumCond.woff2') format('woff2'),
        url('../fonts/DINNextLTPro-MediumCond.woff') format('woff'),
        url('../fonts/DINNextLTPro-MediumCond.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}